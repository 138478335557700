<!-- 商品销售明细  commodity  /Sales_details/commodity  此菜单暂时注释删除掉  -->
<template>
   <section class="cont salesDetails-box">
      <!-- 面包屑  -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>对账报表</el-breadcrumb-item>
            <el-breadcrumb-item> {{ sell_survey == 'house_rate' ? '房费' : sell_survey == 'commodity' ? '商品' : '预售券' }}销售明细</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box" style="position: relative">
         <el-row class="promptText-box">
            <el-alert class="m-bottom-20" title="该日无销售数据，则不显示该日列表" show-icon type="warning" :closable="false" ></el-alert>
         </el-row>
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class='search-item'>
                  <label>营业时间：</label>
                  <el-date-picker
                        v-model="pickerTime" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                  </el-date-picker>
               </el-row>
              <HotelSelect :storeIdQ="storeId" v-if="selectshow" @setstoreId="getDate"> </HotelSelect>
               <el-button class="bg-gradient" @click="queryEvent">搜索</el-button>
               <el-button class="bg-gradient" @click="resetEvent">重置</el-button>
               <el-button class="bg-gradient" @click="exportEvent">导出</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格 -->
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号" type="index" width="60"></el-table-column>
               <el-table-column label="日期" min-width="100" prop="date"></el-table-column>
               <el-table-column label="销售额" prop="totalMoney"></el-table-column>
               <el-table-column label="实际收入" prop="incomeMoney"></el-table-column>
               <el-table-column :label="sell_survey == 'house_rate' ? '订房数量' : sell_survey == 'commodity' ? '订单数量' : '预售券销售数' " prop="orderCount" v-if="sell_survey != 'pre_sale_coupon'"></el-table-column>
               <el-table-column :label="sell_survey == 'house_rate' ? '订房类型' : sell_survey == 'commodity' ? '涉及分类' : '预售券种类数'" prop="roomTypeNum"></el-table-column>
               <el-table-column label="购买人数" prop="userNum"></el-table-column>
               <el-table-column :label="sell_survey == 'house_rate' ? '平均房价' : '客单价' " >
                  <template slot-scope="scope">
                     <span>{{ scope.row.averagePrice | filterPrice }} </span>
                  </template>
               </el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="checkEvent(scope.row.date,sell_survey,hotelInfo.id)" v-if="scope.row.date && selectshow==false ">
                        <el-link type="primary">详情</el-link>
                     </el-button>
                    <el-button type="text" @click="checkEvent(scope.row.date,sell_survey,storeId)" v-if="scope.row.date && selectshow==true ">
                      <el-link type="primary">详情</el-link>
                    </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
   </section>
</template>
<script>
import { urlObj , business } from '@/api/interface'
import { reconciliation } from '@/api/interface/business'
import common from '@/common/js/common'
import { mapState } from "vuex";
export default {
   name: "houseDetailed",
   data(){
      return{
         sell_survey:"",            //报表类型
         pickerTime:[],             //营业时间
        selectshow:false,
         pickerOptions: {
            disabledDate(time) {
               return time.getTime() >= (Date.now()-8.64e7);
            }
         },
         loading:true,              // 表格加载效果
          storeId:'',
         tableData:[],              // 表格数据
         total: 0,                  // 用户列表总条目数
         page: 1,                   // 当前页 默认第一页
         limit: 0,                 // 每页显示数
      }
   },
   watch:{
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.getList()
            }
         },
         deep: true
      },
      $route(to) {
         if (to.name === 'SalesDetails'){
            this.sell_survey = this.$route.params.sell_survey
            this.pickerTime = []
            this.getList()
         }
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.sell_survey = this.$route.params.sell_survey
     this.storeId = this.hotelInfo.id
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()
     if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
       this.selectshow = true
     }
   },
   methods:{
     getDate(val){
       this.storeId = val
     },
      //获取表格数据
      getList(){
         this.loading = true
         this.tableData = []
         let param = ''
         if(this.sell_survey == 'house_rate'){
            if(!this.pickerTime){
               this.pickerTime = []
            }
            const url = reconciliation.getRoomRateDetail + `?limit=${this.limit}&page=${this.page}`
            param = {
               beginTime: this.pickerTime[0],
               endTime: this.pickerTime[1],
               hotelId: this.hotelInfo.id,
            }
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
             param.hotelId= this.storeId
             param.storeId = this.hotelInfo.storeId
           }
           this.$axios.get(url,param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.tableData = res.records
                  this.total = res.total
               }
            })
         }else if(this.sell_survey == 'commodity'){
            console.log("商品销售明细")
         }else{
            const url = reconciliation.getStatisticsCouponMyself + `?limit=${this.limit}&page=${this.page}`
            param = {
               beginDate: this.pickerTime[0],
               endDate: this.pickerTime[1],
               hotelId: this.hotelInfo.id
            }
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
             param.hotelId = this.storeId
             param.storeId = this.hotelInfo.storeId
           }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  this.loading = false
                  let arr = []
                  res.records.forEach((item,index)=>{
                     let perPrice = ''
                     if(item.totalMoney == 0 ){
                        perPrice = 0
                     }else if(item.todayOrderCount == 0){
                        perPrice = '错误'
                     }else{
                        perPrice = item.totalMoney/item.todayOrderCount
                        // ( parseInt( item.totalMoney/item.todayOrderCount * 100 ) / 100 ).toFixed(2)
                     }
                     arr.push(
                         Object.assign({
                            date:item.date,
                            totalMoney:item.totalMoney,   // 销售额
                            incomeMoney:item.actualAmount,// 实际收入
                            orderCount:"预售券销售数",      // 预售券销售数 现在暂时注释掉了
                            roomTypeNum:item.saleCount,      // 预售券种类数
                            userNum:item.todayOrderCount,// 购买人数
                            averagePrice:perPrice // 销售额/购买人数=客单价
                         })
                     )
                  })
                  this.tableData = arr
                  this.total = res.total
               }
            })
         }
      },
      // 搜索
      queryEvent(){
         this.getList()
      },
      // 重置
      resetEvent(){
         this.pickerTime = []
         this.storeId = ''
      },
      //导出事件
      exportEvent() {
         if (!this.pickerTime || !this.pickerTime.length) {
            this.$message('请选择要导出的营业时间范围');
         } else {
            if (this.sell_survey == 'house_rate') {
               const url = reconciliation.getRoomRateDetailExcel
               let param = {
                  limit:this.limit,
                  page:this.page,
                  beginTime: this.pickerTime[0],
                  endTime: this.pickerTime[1],
                  hotelId: this.hotelInfo.id
               }
               this.$axios.get(url, param, {}, 'blob').then(res => {
                  common.exportFile(res, '订房销售明细')
               })
            } else if (this.sell_survey == 'commodity') {
               console.log("商品销售明细导出")
            } else {
               const url = reconciliation.CouponExcel
               let param = {
                  limit:this.limit,
                  page:this.page,
                  beginDate: this.pickerTime[0],
                  endDate: this.pickerTime[1],
                  hotelId: this.hotelInfo.id
               }
              if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
                param.hotelId = this.storeId
                param.storeId = this.hotelInfo.storeId
              }
               this.$axios.get(url, param, {}, 'blob').then(res => {
                  common.exportFile(res, '预售券报表')
               })
            }
         }
      },
      //详情
      checkEvent(row,sell_survey,id){
         sessionStorage.setItem('houseDetaInfo', JSON.stringify({date:row,state:sell_survey,id}))
         this.$router.push("/Sales_details")
      },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getList()
      },
   },
   filters:{
      filterPrice(val){
         let num =  (parseInt( val * 100 ) / 100 ).toFixed(2)
         if(num === 'NaN') return 0
         return num;
      }
   }
}
</script>
<style scoped lang="scss">
.salesDetails-box{
   .promptText-box{
      position: absolute;top: 10px;right: 50px;
      color: #cacaca;
      font-size: 14px;
   }
}
</style>
